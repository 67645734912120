import { useQuery } from "react-query";
import { globalSearch } from "../api/globalSearchApi";

export function FindGlobalSearchContent({ text}) {
    const { data, isLoading } = useQuery(
      ["Global-Search", { text }],
      globalSearch
    );
    if (isLoading) return isLoading;
    else return data;
  }