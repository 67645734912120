export const language = [
    {
        "country": "IN",
        "regionCode": "CH",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "DL",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "GJ",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "HR",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "HP",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "JK",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "MH",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "PY",
        "lang": "ta"
    },
    {
        "country": "IN",
        "regionCode": "PB",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "RJ",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "TN",
        "lang": "ta"
    },
    {
        "country": "IN",
        "regionCode": "BR",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "MP",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "UP",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "CT",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "JH",
        "lang": "hi"
    },
    {
        "country": "IN",
        "regionCode": "UT",
        "lang": "hi"
    },
    {
        "country": "LK",
        "regionCode": "7",
        "lang": "ta"
    },
    {
        "country": "LK",
        "regionCode": "5",
        "lang": "ta"
    },
    {
        "country": "LK",
        "regionCode": "4",
        "lang": "ta"
    },
    {
        "country": "LK",
        "regionCode": "1",
        "lang": "ta"
    },
    {
        "country": "NP",
        "regionCode": "BA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "BH",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "DH",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "GA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "JA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "KA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "KO",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "LU",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "MA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "ME",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "NA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "RA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "SA",
        "lang": "hi"
    },
    {
        "country": "NP",
        "regionCode": "SE",
        "lang": "hi"
    }
]
