import "../styles/globals.css";
import Footer from "../components/Footer";
import Script from "next/script";
import React, { useEffect } from "react";

import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import { store } from "../store";
import Radio from "../components/Radio";
import Header from "../components/Header";
import ShaivamAudioPlayer from "../components/AudioPlayer";

const queryClient = new QueryClient();

// copyListener ....
const copyListener = (event) => {
  event.preventDefault();

  const range = window.getSelection().getRangeAt(0);
  const rangeContents = range.cloneContents();
  const pageLink = `Read more at: ${document.location.href}`;
  const helper = document.createElement("div");

  helper.appendChild(rangeContents);
  helper.innerHTML += `\n${pageLink}`;

  const copiedText = helper.textContent || helper.innerText;
  event.clipboardData.setData('text/plain', copiedText);
};

//Added by Shaivam.org to handle internal link navigation in an HTML page - Aug2024
const handleClick = (event) => {
  const srcElement = event.target;

  //Check if the clicked element is an anchor tag with an internal link
  if (srcElement.tagName === "A" && srcElement.href.includes("#")) {
    const targetElementId = srcElement.href.split("#")[1];
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
        event.preventDefault();
        targetElement.scrollIntoView({ behavior: "smooth" });
        //update the URL hash without reloading the page
        history.pushState(null, "", `#${targetElementId}`);
    }
  }
};

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    document.addEventListener("copy", copyListener);
    //Added by Shaivam.org to handle internal link navigation in an HTML page - Aug2024
    //Attach the click event listener to handle internal link navigation
    document.addEventListener("click", handleClick);

    //Cleanup function to remove the event listeners when the component unmounts
    return () => {
      document.removeEventListener("copy", copyListener);
      document.removeEventListener("click", handleClick);
    };

  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      const { state } = event;

      if (state && state.isBackButton) {
        // Go to the previous URL
        window.location.href = state.previousUrl;
      }
    };

    window.onpopstate = handlePopState;

    return () => {
      window.onpopstate = null;
    };
  }, []);

  return (
    <>
      {/* google analytics added !! */}
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-6QS01PDC3J"
      ></Script>
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-6QS01PDC3J', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      {/* google analytics ends */}
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <div className="container">
            <Header />
            <Component {...pageProps} />
            <Radio />
            <ShaivamAudioPlayer />
            <Footer />
          </div>
        </QueryClientProvider>
      </ReduxProvider>
    </>
  );
}

export default MyApp;
