import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userData: {},
  IsLogedInState: false,
  isProfileImageChange: "",
};

const counterSlice = createSlice({
  name: "setUserData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsLogedInState: (state, action) => {
      state.IsLogedInState = action.payload;
    },
    setIsProfileImageChange: (state, action) => {
      state.isProfileImageChange = action.payload;
    },
  },
});

export const { setUserData, setIsLogedInState, setIsProfileImageChange } =
  counterSlice.actions;

export default counterSlice.reducer;
