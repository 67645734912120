import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  quizAnswers: {},
  results: {},
  emailForConfirm:"",
  isHubId:-1,
};

const counterSlice = createSlice({
  name: "actions",
  initialState,
  reducers: {
    quiz: (state, action) => {
      state.quizAnswers = { ...state.quizAnswers, ...action.payload };
    },
    quizResult: (state, action) => {
      state.results = { ...state.results, ...action.payload };
    },
    clearQuiz: (state, _action)=>{
      state.quizAnswers ={}
      state.results = {}
    },
    setConfirmEmail: (state, action)=>{
       state.emailForConfirm = action.payload
    } ,
    setHubId: (state, action)=>{
       state.isHubId = action.payload
    }
   },
});

export const { quiz, quizResult, clearQuiz, setConfirmEmail, setHubId } = counterSlice.actions;

export default counterSlice.reducer;
