import { useInfiniteQuery, useQuery } from "react-query";
import { featured_Contents, featurePrayerOfTheDay, getWhatsNew, prayerOfTheDay, special_featured_Contents, upcoming_Events, Update_Bar_Contents, Update_galleries } from "../api/homepageApi";

export function getUpcomingEvents() {
  const { data, isLoading } = useQuery("upcoming_events", upcoming_Events);

  if (isLoading) return isLoading;
  else return data;
}

export function getwhatsNews(filter) {
  const { data, isLoading } = useQuery(["Whats_new",{filter}], getWhatsNew);

  if (isLoading) return isLoading;
  else return data;
}

export function getUpdateBar(filter) {
  const { data, isLoading } = useQuery(["Update_bar",{filter}], Update_Bar_Contents);

  if (isLoading) return isLoading;
  else return data;
}

export function getFeaturedContents() {
  const { data, isLoading } = useQuery("featured_contents", featured_Contents);

  if (isLoading) return isLoading;
  else return data;
}

export function get_special_featured_Contents(filter) {
  const { data, isLoading } = useQuery(["special_featured_Contents",{filter}], special_featured_Contents);

  if (isLoading) return isLoading;
  else return data;
}

export function getPrayerOfTheDay(pageParam) {
  const { data, isLoading } = useInfiniteQuery(["Prayer_Of_The_day",{pageParam}], prayerOfTheDay,{
    keepPreviousData: false,
    getNextPageParam: (lastPage, _pages) => {
      if (pageParam > lastPage?.meta?.pagination?.total)
        return undefined;
      else return 1;
    },
    cacheTime:0,
    select: (data) => data?.pages[0],
  });

  if (isLoading) return isLoading;
  else return data;
}

export function getFeaturedPrayerOfTheDay() {
  const { data, isLoading } = useQuery(["Featured-Prayer-Of-TheDay"], featurePrayerOfTheDay);

  if (isLoading) return isLoading;
  else return data;
}

export function getGallaries(pageParam) {
  const { data, isLoading } = useQuery(["home-page-galleries",{pageParam}], Update_galleries);

  if (isLoading) return isLoading;
  else return data;
}