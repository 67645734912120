import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isRadioSelected: true,
  RadioSong:[],
  RadioNextSong:[],
  RadioMeta:{}
};

const radioSlice = createSlice({
  name: "radio",
  initialState,
  reducers: {
    radioStateChange: (state, action) => {
      state.isRadioSelected = action.payload;
    },
    radioChangeInfo: (state, action) => {
      state.RadioSong = action.payload.radioSong;
      state.RadioMeta = action.payload.radioMeta;
      state.RadioNextSong = action.payload.radioNextSong
    },
  },
});

export const { radioStateChange , radioChangeInfo} = radioSlice.actions;

export default radioSlice.reducer;
