import { useEffect, useRef, useState } from "react";

export const useAudio = (url) => {
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [audio, setAudio] = useState(null);
  const abortControllerRef = useRef(null);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    if (playing) {
      // Create a new AbortController instance and save it to the ref
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;

      // Use the AbortController's signal to abort the request if needed
      const audioObj = new Audio(url, { signal });
      setAudio(audioObj);
      audioObj.addEventListener("ended", () => setPlaying(false));
      audioObj.play().catch((error) => {
        if (error.name === "AbortError") {
          // The request was aborted
          console.log("Audio request was aborted");
        } else {
          // Some other error occurred
          console.error(error);
        }
      });
    } else {
      if (audio) {
        audio.pause();
        setAudio(null);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
    return () => {
      if (audio) {
        audio.pause();
        setAudio(null);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [playing, muted, url]);

  useEffect(() => {
    if (audio && muted) {
      audio.muted = muted;
    }
  }, [muted, audio]);

  return { playing, setPlaying, muted, setMuted, toggle };
};
