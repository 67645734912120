import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  globalLang: "",
  globalLanguageForApi:null
};

const langSlice = createSlice({
  name: "globalLang",
  initialState,
  reducers: {
    globalLanguages: (state, action) => {
      state.globalLang = action.payload;
    },
    globalLanguagesForApiChange: (state, action) => {
      state.globalLanguageForApi = action.payload;
    }
  },
});

export const { globalLanguages,globalLanguagesForApiChange } = langSlice.actions;

export default langSlice.reducer;
