/** @format */

import { isNil, isNull } from "lodash";
import { BiLinkExternal } from "react-icons/bi";

export const handleAuth = () => {
  return localStorage.getItem("user");
};
export const handleLang = () => {
  return localStorage.getItem("language");
};

export const getLang = () => {
  return JSON.parse(localStorage?.getItem("language"));
};

export const handleExpire = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("shaivamToken");
};

export const getTokenHandler = () => {
  return localStorage.getItem("shaivamToken");
};

export const setlangForUser = (lancode) => {
  return localStorage.setItem("language", JSON.stringify(lancode));
};

export const IsLocalStorageEmptyForApiCall = (item) => {
  if (isNull(item) || isNil(item)) return "en";
  return JSON.parse(item);
};

export const ContentFormatter = (content) => {
  let rawContent = content;
  // remove h1 regex ..
  const contentRegex = /(<h1[\s\S]*?<\/h1>)/;
  rawContent = content.replace(contentRegex, "");

  // update http to https regex ..
  rawContent = rawContent.replaceAll("http:", "https:");

  // handle the pdf parsing ...
  let pdfRegex = /<a href=("[^"]+\.pdf".*?)<\/a>/g;
  rawContent = rawContent.replace(
    pdfRegex,
    `<svg style="color: c25950;" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" class="Header_icon__BPVd4" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M531.3 574.4l.3-1.4c5.8-23.9 13.1-53.7 7.4-80.7-3.8-21.3-19.5-29.6-32.9-30.2-15.8-.7-29.9 8.3-33.4 21.4-6.6 24-.7 56.8 10.1 98.6-13.6 32.4-35.3 79.5-51.2 107.5-29.6 15.3-69.3 38.9-75.2 68.7-1.2 5.5.2 12.5 3.5 18.8 3.7 7 9.6 12.4 16.5 15 3 1.1 6.6 2 10.8 2 17.6 0 46.1-14.2 84.1-79.4 5.8-1.9 11.8-3.9 17.6-5.9 27.2-9.2 55.4-18.8 80.9-23.1 28.2 15.1 60.3 24.8 82.1 24.8 21.6 0 30.1-12.8 33.3-20.5 5.6-13.5 2.9-30.5-6.2-39.6-13.2-13-45.3-16.4-95.3-10.2-24.6-15-40.7-35.4-52.4-65.8zM421.6 726.3c-13.9 20.2-24.4 30.3-30.1 34.7 6.7-12.3 19.8-25.3 30.1-34.7zm87.6-235.5c5.2 8.9 4.5 35.8.5 49.4-4.9-19.9-5.6-48.1-2.7-51.4.8.1 1.5.7 2.2 2zm-1.6 120.5c10.7 18.5 24.2 34.4 39.1 46.2-21.6 4.9-41.3 13-58.9 20.2-4.2 1.7-8.3 3.4-12.3 5 13.3-24.1 24.4-51.4 32.1-71.4zm155.6 65.5c.1.2.2.5-.4.9h-.2l-.2.3c-.8.5-9 5.3-44.3-8.6 40.6-1.9 45 7.3 45.1 7.4zm191.4-388.2L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path></svg> <pdf pdfUri=$1 </pdf>`
  );

  // // handle http regex ..
  let httpRegex = /<a href="http([^"]+\".*?)<\/a>/g;
  rawContent = rawContent.replace(
    httpRegex,
    `<a target="_blank" href="http$1 </a> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="Header_icon__BPVd4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m13 3 3.293 3.293-7 7 1.414 1.414 7-7L21 11V3z"></path><path d="M19 19H5V5h7l-2-2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-5l-2-2v7z"></path></svg>`
  );

  return rawContent;
};

const triggerPDF = (e, setPopup, setURI) => {
  var _target = e?.target.querySelector("pdf");
  if (_target) {
  } else {
    var _target = e?.target;
  }

  if (_target.getAttribute("pdfUri")) {
    console.log(_target.getAttribute("pdfUri"));
    setPopup(true);
    setURI(_target.getAttribute("pdfUri"));
  }
};

// import for pdf component ...
import { Pdf } from "../components/pdf";
import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/Slug.module.css";

export const SetRichTextContent = (content) => {
  const [popup, setPopup] = useState(false);
  const [uri, setURI] = useState(false);
  const divRef = useRef(null);
  
  useEffect(() => {
    // Automatically trigger the click when the component mounts
    // triggerClick();

  }, []);
  console.log(uri,'uri outside of onClick'); //false 
  const triggerClick = ()=> {
    if (divRef.current) {
      divRef.current.click();
    }    
  };

  const handleClick = (e) => {
  
    alert("Div clicked!" + e.target);
  };

  return (
    <>

      {popup && <Pdf uri={uri} setPopup={setPopup} />}
      <div
        style={{
          width: "100%",
          paddingRight: "10%",
        }}
        id="myFrame"
        ref={divRef}
        className={styles.slugContent}
        onClick={(e) => {
          if(e.target.tagName == 'PDF'){
            triggerPDF(e, setPopup, setURI);
          }
        }}
        dangerouslySetInnerHTML={{
          __html: ContentFormatter(content ? content : ""),
        }}
      />
    </>
  );
};

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

export const clearSlug = (slug) => {
  let modifiedSlug = [];
  const invalid = ["undefined", "service-worker.js", "favicon.ico"];
  for (let s of slug) {
    if (!isNumeric(s.trim()) && !invalid.includes(s)) {
      modifiedSlug.push(s);
    }
  }
  return modifiedSlug;
};

export function contactEmailFormatter(data) {
  const Formatter = `
   <p> name: ${data?.name || ""} </p>
   <br>
    <p> email: ${data?.email || ""} </p>
    <br>
    <p> phone: ${data?.phone || ""} </p>
    <br>
    <p> description: ${data?.description || ""} </p>
    `;

  return Formatter;
}

export function formatAudio(data) {
  if (!data) return;
  let info;
  const songs = data?.split("*");

  const songInfo = songs?.map((s, i) => {
    info = s.split("#");
    let temp = {};
    if (info?.length == 1) {
      temp = {
        attributes: {
          title: `Song ${i + 1}`,
          rawURL: info[0],
        },
      };
    }
    if (info?.length > 1) {
      temp = {
        attributes: {
          title: info[0],
          rawURL: info[1],
        },
      };
    }
    return temp;
  });
  return songInfo;
}

export function getWeekOfMonth(date) {
  var nth = 0; // returning variable.
  var timestamp = date.getTime(); // get UTC timestamp of date.
  var month = date.getMonth(); // get current month.
  var m = month; // save temp value of month.
  let week;

  while (m == month) {
    // check if m equals our date's month.
    nth++; // increment our week count.
    // update m to reflect previous week (previous to last value of m).
    m = new Date(timestamp - nth * 604800000).getMonth();
  }
  if (nth == 1) {
    week = "one";
  }
  if (nth == 2) {
    week = "two";
  }
  if (nth == 3) {
    week = "three";
  }
  if (nth == 4) {
    week = "four";
  }
  if (nth == 5) {
    week = "last";
  }

  return week;
}

export function findTamilCharacter(character) {
  if (!character) return false;

  if (character.match(/[\u0b80-\u0bff][\u0bbe-\u0bcd\u0bd7]?/)) {
    return true;
  }

  return false;
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function formatDateStringToISO(date) {
  // Extract date parts
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  // Create formatted date string
  const formattedDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return formattedDateString;
}
