import { getTokenHandler } from "../utils/Helper";
import { Config } from "../utils/config";

export const signUpUser = async ({ name, email, password }) => {
  return fetch(`${Config.BaseUrl}/auth/local/register`, {
    method: "POST",
    body: JSON.stringify({
      name: name,
      email: email,
      username: email,
      password: password,
    }),
    headers: { "Content-Type": "application/json" },
  });
};

export const loginUser = async ({ email, password }) => {
  return fetch(`${Config.BaseUrl}/auth/local`, {
    method: "POST",
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
    headers: { "Content-Type": "application/json" },
  });
};

export const forgot_Password = async ({ email }) => {
  return fetch(`${Config.BaseUrl}/auth/forgot-password`, {
    method: "POST",
    body: JSON.stringify({
      email: email,
      // url: 'http://localhost:3000/reset-password'
    }),
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.json());
};

export const reset_Password = async ({
  password,
  passwordConfirmation,
  code,
}) => {
  return fetch(`${Config.BaseUrl}/auth/reset-password`, {
    method: "POST",
    body: JSON.stringify({
      password: password,
      passwordConfirmation: passwordConfirmation,
      code: code,
    }),

    headers: { "Content-Type": "application/json" },
  });
};

export const usersInfo = async () => {
  return fetch(`${Config.BaseUrl}/users`).then((res) => res.json());
};

export const userDetails = async ({ queryKey }) => {
  const { userId } = queryKey[1];
  return fetch(`${Config.BaseUrl}/users/${userId}?populate=*`).then((res) =>
    res.json()
  );
};

export const userInfo = async (userId) => {
  return fetch(`${Config.BaseUrl}/users/${userId}?populate=*`).then((res) =>
    res.json()
  );
};

export const userUpdate = async (data) => {
  const {  name, id } = data;

  return fetch(`${Config.BaseUrl}/users/${id}`, {
    method: "PUT",
    body: JSON.stringify({ name: name }),
    headers: { "Content-Type": "application/json" },
  }).then((res) => res.json());
};

export const userUpdatePassword = async (data) => {
  const { password,currentPassword,passwordConfirmation } = data;
  const   token = getTokenHandler()

  return fetch(`${Config.BaseUrl}/auth/change-password`, {
    method: "POST",
    body: JSON.stringify({ currentPassword:currentPassword, password: password,passwordConfirmation:passwordConfirmation }),
    headers: { "Content-Type": "application/json",
    'Authorization': `Bearer ${JSON.parse(token)}`
  
  },
  }).then((res) => res.json());
};


export const userGoogleLogin = async (data) => {
  const url = `${Config.BaseUrl}/auth/google/callback/?${data}`
  return fetch(url)
};

export const userFaceBookLogin = async (data) => {
  
  return fetch(`${Config.BaseUrl}/auth/facebook/callback/?${data}`)
};