import React from "react";
import Link from "next/link";
import homedata from '../data/Homepage.json'
import styles from "../styles/Footer.module.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { handleLang } from "../utils/Helper";

const Footer = () => {


  const [footerSectionData, setFooterSectionData] = useState("");
  const [selectLang, setSelectedLang] = useState("");
  const globalselectedlang = useSelector((state) => state.language.globalLang);


  useEffect(() => {
    const lang = handleLang()
    const language = JSON.parse(lang)
    if (globalselectedlang == "en" || language == "en") {
      setFooterSectionData(homedata.en)
    }
    if (globalselectedlang == "hi" || language == "hi") {
      setFooterSectionData(homedata.hi)
    }
    if (globalselectedlang == "ta" || language == "ta") {
      setFooterSectionData(homedata.ta)
    }
    setSelectedLang(language)
  }, [globalselectedlang])


















  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerWrapper}>
          <div className={styles.fContent}>
            <div className={styles.one}>
              <img
                src="/assests/icons/logo.png"
                className={styles.logo}
                alt=""
              />
              <div className={styles.details}>
                <Link href="/">
                  <a> Shaivam.org </a>
                </Link>
                {/* <p>World Be Free From Suffering</p> */}
              </div>
            </div>
            <div className={styles.two}>
              <h1> {footerSectionData?.Footer?.menu1?.title} </h1>
              {
                footerSectionData?.Footer?.menu1?.data?.map((item, index) => {


                  return <Link href={item?.url} key={index} >
                    <a>{item.title} </a>
                  </Link>
                })
              }

            </div>

            <div className={styles.three}>
              <h1>{footerSectionData?.Footer?.menu2?.title}</h1>
              {
                footerSectionData?.Footer?.menu2?.data.map((item, index) => {


                  return <Link href={item?.url}>
                    <a>{item.title} </a>
                  </Link>
                })
              }
            </div>

            <div className={styles.four}>
              <h1>{footerSectionData?.Footer?.menu3?.title}</h1>
              {
                footerSectionData?.Footer?.menu3?.data?.map((item, index) => {


                  return <Link href={item?.url} key={index} >
                    <a>{item.title} </a>
                  </Link>
                })
              }
            </div>
          </div>

          <hr />
        </div>

        <div className={styles.lowerthird}>
          <div>
            <span>
              {footerSectionData?.Footer?.FooterDetails?.title}
            </span>
          </div>
          <div>
            <Link href="https://play.google.com/store/apps/details?id=org.shaivam">
              <a>
                <img src="/assests/icons/googleplay.png" alt="" 
                style={{ height: "30px" }} />
              </a>
            </Link>
            <Link href="https://apps.apple.com/in/app/shaivam-org-mobile-app/id1575138510">
              <a>
                <img src="/assests/icons/appstore.png" alt="" style={{ height: "30px" }} />
              </a>
            </Link>
            <Link href="">
              <a href="https://www.facebook.com/shaivamorg" target="_blank" >
                <img src="/assests/icons/fbyellow.svg" alt="" />
              </a>
            </Link>
            <Link href="">
              <a href="https://twitter.com/#!/shaivamorg" target="_blank">
                <img src="/assests/icons/twt.svg" alt="" />
              </a>
            </Link>
            <Link href="">
              <a href="https://youtube.com/shaivamorg" target="_blank" >
                <img src="/assests/icons/yt.svg" alt="" />
              </a>
            </Link>
          </div>
        </div>
      </div>

      {/* ----------------------------------------Mobile footer----------------------------------- */}

      {/* <div className={styles.footerMobile}> */}
      <div className={styles.footerWrapperMobile}>
        <div className={styles.oneMobile}>
          <img src="/assests/icons/logo.png" height={50} width={50} alt="" />
          <div className={styles.detailsMobile}>
            <Link href="/">
              <a> Shaivam.org </a>
            </Link>
            {/* <p>World Be Free From Suffering</p> */}
          </div>

        </div>
        <div className={styles.ytIcons}>
          <Link href="">
            <a href="https://www.facebook.com/shaivamorg" target="_blank">
              <img src="/assests/icons/fbyellow.svg" alt="" />
            </a>
          </Link>
          <Link href="">
            <a href="https://twitter.com/#!/shaivamorg" target="_blank" style={{ margin: " 0rem 2rem" }}>
              <img src="/assests/icons/twt.svg" alt="" />
            </a>
          </Link>
          <Link href="">
            <a href="https://youtube.com/shaivamorg" target="_blank" >
              <img src="/assests/icons/yt.svg" alt="" />
            </a>
          </Link>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} >
          <Link href="https://play.google.com/store/apps/details?id=org.shaivam">
            <a>
              <img src="/assests/icons/googleplay.png" alt="" style={{ height: "30px" }} />
            </a>
          </Link>
          <Link href="apps.apple.com/in/app/shaivam-org-mobile-app/id1575138510">
            <a>
              <img src="/assests/icons/appstore.png" alt="" style={{ height: "30px", marginLeft: "10px" }} />
            </a>
          </Link>
        </div>
        <div className={styles.divWrapper}>
          <div className={styles.twoMobile}>
            <h1> {footerSectionData?.Footer?.menu1?.title} </h1>
            {
              footerSectionData?.Footer?.menu1?.data?.map((item, index) => {


                return <Link href={item?.url} key={index}>
                  <a>{item.title} </a>
                </Link>
              })
            }
          </div>

          <div className={styles.threeMobile}>
            <h1> {footerSectionData?.Footer?.menu2?.title} </h1>
            {
              footerSectionData?.Footer?.menu2?.data?.map((item, index) => {


                return <Link href={item?.url} key={index} >
                  <a>{item.title} </a>
                </Link>
              })
            }
          </div>

          <div className={styles.fourMobile}>
            <h1> {footerSectionData?.Footer?.menu3?.title} </h1>
            {
              footerSectionData?.Footer?.menu3?.data?.map((item, index) => {


                return <Link href={item?.url} key={index} >
                  <a>{item.title} </a>
                </Link>
              })
            }
          </div>

        </div>

      </div>

      {/* <div className={styles.lowerthirdMobile}>
          <div>
            <span>
              2017 - 2022 Shaivam.Org | Powered by Codewave Technologies{" "}
            </span>
          </div>
          <div className={styles.ytIcons}>
            <Link href="">
              <a>
                <img src="/assests/icons/fbyellow.svg" alt="" />
              </a>
            </Link>
            <Link href="">
              <a>
                <img src="/assests/icons/twt.svg" alt="" />
              </a>
            </Link>
            <Link href="">
              <a>
                <img src="/assests/icons/yt.svg" alt="" />
              </a>
            </Link>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Footer;
