import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isAudioSelected: false,
  url: "",
  title: "",
  album: "",
  songs: [],
  songIndex: -1,
  playingIndex: -1,
  isDownload: false,
};

const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    audioStateChange: (state, action) => {
      state.isAudioSelected = action.payload;
    },
    audioInfoChange: (state, action) => {
      state.title = action.payload.title;
      state.url = action.payload.url;
      state.album = action.payload.album;
      state.songIndex = action.payload.songIndex;
      state.isDownload = action.payload.isDownload;
    },
    updateSongs: (state, action) => {
      state.songs = action.payload.songs;
    },
    updateIndex: (state, action) => {
      state.playingIndex = action.payload;
    },
  },
});

export const { audioStateChange, audioInfoChange, updateSongs, updateIndex } =
  audioSlice.actions;

export default audioSlice.reducer;
