import { Config } from "../utils/config";

export const getRadios = async ({ queryKey }) => {
    const { pageParam,sort } = queryKey[1];
    return fetch(`${Config.BaseUrl}/radios?pagination[pageSize]=1&pagination[page]=${pageParam}&sort=${sort==1?"language":"Order"}:ASC`).then((res) =>
      res.json()
    );
  };

  export const getNextRadios = async ({ queryKey }) => {
    const { pageParamNext } = queryKey[1];
    return fetch(`${Config.BaseUrl}/radios?pagination[pageSize]=1&pagination[page]=${pageParamNext}`).then((res) =>
      res.json()
    );
  };