import AudioPlayer from "react-h5-audio-player";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateIndex, audioStateChange } from "../actions/audioSlice";

import { Config } from "../utils/config";

import "react-h5-audio-player/lib/styles.css";
import styles from "../styles/Audio.module.css";
import { BiPause } from "react-icons/bi";
import { LuRepeat } from "react-icons/lu";

const ShaivamAudioPlayer = () => {
  const album = useSelector((state) => state.audio.album);
  const songsArr = useSelector((state) => state.audio.songs);
  const isDownload = useSelector((state) => state.audio.isDownload);
  const indexOfSong = useSelector((state) => state.audio.songIndex);
  const isAudio = useSelector((state) => state.audio.isAudioSelected);

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setTrackIndex] = useState(0);
  const [isLooping, setIsLooping] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
  

    setTrackIndex(indexOfSong);
  }, [indexOfSong]);

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < songsArr.length - 1 ? currentTrack + 1 : currentTrack
    );
  };
  const handleClickPrev = () => {
    setTrackIndex((currentTrack) => (currentTrack > 0 ? currentTrack - 1 : 0));
  };

  const handleEnd = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < songsArr.length - 1 ? currentTrack + 1 : 0
    );
  };

  useEffect(() => {
    dispatch(updateIndex(currentTrack));
  }, [currentTrack]);

  const handleClickOnLoop = () => {
    // setIsLooping(true);
    setIsLooping((prevLooping) => !prevLooping);
  };

  // useEffect(() => {
  //   setTrackIndex(0);
  // }, [album]);

  // const onButtonClick = (url, name) => {
  //   console.log({ url });
  //   console.log({ name });
  //   fetch(
  //     "https://shaivamfiles.fra1.cdn.digitaloceanspaces.com/gallery/audio/marathi/parayanam/rajanikant-chandwadkar/shri-shiva-leelamruta/mrt-par-rajanikant-chandwadkar-14-shri-shiva-leelamruta-adhyay12.mp3"
  //   ).then((response) => {
  //     response.blob().then((blob) => {
  //       let url = window.URL.createObjectURL(blob);
  //       let a = document.createElement("a");
  //       a.href = url;
  //       a.download = `${name}.mp3`;
  //       a.click();
  //     });
  //   });
  // };

  return (
    <>
      {isAudio ? (
        <div className={styles.Musicaudio}>
          <div className={styles.Musictitle}>
            <div
              className={styles.titleImg}
              // onClick={() => router.push("/radio")}
            >
              <img src="/assests/icons/music.svg" alt="" />
            </div>
            <div>
              <h5> {songsArr[currentTrack]?.attributes?.title}</h5>

              <p>{album}</p>
            </div>
          </div>

          <div
            id="waveform"
            className={styles.musicSlider}
            style={{ position: "relative" }}
          >
            <AudioPlayer
              autoPlay={true}
              showSkipControls
              onEnded={handleEnd}
              listenInterval={true}
              onClickNext={handleClickNext}
              onPlay={() => setIsPlaying(true)}
              onClickPrevious={handleClickPrev}
              onPause={() => setIsPlaying(false)}
              src={
                songsArr[currentTrack]?.attributes?.rawURL ? 
                // If Url Exist .. proceed with further conditions ...
                `${
                  // if url includes shaivam.org ...
                  songsArr[currentTrack]?.attributes?.rawURL.includes("shaivam.org")  
                  
                  ? 
                  // prepare the url by replacing the url value ..
                  songsArr[currentTrack]?.attributes?.rawURL.replace("https://shaivam.org",Config.audioUrl)
                  : 
                  songsArr[currentTrack]?.attributes?.rawURL.startsWith(Config.audioUrl) ? 
                  `${songsArr[currentTrack]?.attributes?.rawURL}` :

                  `${Config.audioUrl}${songsArr[currentTrack]?.attributes?.rawURL}` 
                }`: `${
                  // if url includes shaivam.org ...
                  songsArr[currentTrack]?.attributes?.rawSrc.includes("shaivam.org")  
                  
                  ? 
                  // prepare the url by replacing the url value ..
                  songsArr[currentTrack]?.attributes?.rawSrc.replace("https://shaivam.org",Config.audioUrl)
                  : 
                  songsArr[currentTrack]?.attributes?.rawSrc.startsWith(Config.audioUrl) ? 
                  `${songsArr[currentTrack]?.attributes?.rawSrc}` :

                  `${Config.audioUrl}${songsArr[currentTrack]?.attributes?.rawSrc}` 
                }` 
                                                                                                                                                                                                                                                                                                                                                                                 
                // If Not Exist Set it to blank ..
               
              }
            />
            <div className={styles.icons} style={{}}>
              <button
                style={{
                  marginRight: "0.5rem",
                  borderColor: currentTrack == 0 ? "#cacaca" : "black",
                }}
              >
                <img
                  src="/assests/icons/rewind3.png"
                  alt=""
                  style={{
                    opacity: currentTrack == 0 ? 0.3 : 1,
                  }}
                />
              </button>
              <button style={{ background: "#fcb300" }}>
                {isPlaying ? (
                  <BiPause className={styles.pause} />
                ) : (
                  <img src="/assests/icons/playaudio.png" alt="" />
                )}
              </button>
              <button
                style={{
                  marginLeft: "0.5rem",
                  borderColor:
                    currentTrack == songsArr?.length - 1 ? "#cacaca" : "black",
                }}
              >
                <img
                  src="/assests/icons/forward.png"
                  alt=""
                  style={{
                    opacity: currentTrack == songsArr?.length - 1 ? 0.3 : 1,
                  }}
                />
              </button>
              <button className={styles.loopRepeatPosition}>
                <span onClick={() => handleClickOnLoop()}>
                  <LuRepeat
                    className={styles.LoopRepeat}
                    style={{ color: isLooping ? "#fcb300" : "black" }}
                  />
                </span>
              </button>
            </div>
          </div>

          <div className={styles.resDownload}>
            {!isDownload && (
              <a
                href={
                  songsArr[currentTrack]?.attributes?.rawURL ? 
                  // If Url Exist .. proceed with further conditions ...
                  `${
                    // if url includes shaivam.org ...
                    songsArr[currentTrack]?.attributes?.rawURL.includes("shaivam.org")  
                    
                    ? 
                    // prepare the url by replacing the url value ..
                    songsArr[currentTrack]?.attributes?.rawURL.replace("https://shaivam.org",Config.audioUrl)
                    : 
                    songsArr[currentTrack]?.attributes?.rawURL.startsWith(Config.audioUrl) ? 
                    `${songsArr[currentTrack]?.attributes?.rawURL}` :
  
                    `${Config.audioUrl}${songsArr[currentTrack]?.attributes?.rawURL}` 
                  }`: songsArr[currentTrack]?.attributes?.rawURL
                
                }
                target="_blank"
              >
                <img src="/assests/icons/download.svg" alt="" />
              </a>
            )}
          </div>
          <div
            onClick={() => {
              dispatch(audioStateChange(false));
              dispatch(updateIndex(-1));
            }}
            className={styles.close}
          >
            <img src="/assests/icons/close.png" alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ShaivamAudioPlayer;
