import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { BiPause } from "react-icons/bi";
import { FaVolumeMute } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { radioChangeInfo, radioStateChange } from "../actions/radioSlice";
import { findRadios } from "../hooks/radioHooks";
import { useAudio } from "../hooks/useAudio";
import styles from "../styles/Radio.module.css";
import { handleLang } from "../utils/Helper";

const Radio = () => {
  const dispatch = useDispatch();
  const [pageParam, setPageParam] = useState(1);
  const [pageParamNext, setPageParamNext] = useState(2);
  const [radioEnable, setRadioEnable] = useState(false);
  const [url, setUrl] = useState("");
  const [sort, setSort] = useState(0);
  const radioState = useSelector((state) => state.radio.isRadioSelected);
  const RadioSong = useSelector((state) => state.radio.RadioSong);
  const RadioMeta = useSelector((state) => state.radio.RadioMeta);
  const RadioNextSong = useSelector((state) => state.radio.RadioNextSong);

  const radioSong = findRadios(pageParam, sort, radioEnable);
  const radioNextSong = findRadios(pageParamNext, sort, radioEnable);
  const globalselectedlang = useSelector((state) => state.language.globalLang);

  const { playing, setPlaying, muted, setMuted, toggle } = useAudio(url);

  useEffect(() => {
    if (RadioSong?.length == 0 && RadioNextSong?.length == 0) {
      setRadioEnable(true);
    }
    if (radioSong?.data?.length > 0 && radioNextSong?.data?.length > 0) {
      setRadioEnable(false);
      dispatch(
        radioChangeInfo({
          radioSong: radioSong?.data,
          radioMeta: radioSong?.meta,
          radioNextSong: radioNextSong?.data,
        })
      );
    }
    if (
      radioSong?.data?.length > 0 &&
      radioSong?.data[0]?.attributes?.Title !== RadioSong[0]?.attributes?.Title
    ) {
      setRadioEnable(false);
      dispatch(
        radioChangeInfo({
          radioSong: radioSong?.data,
          radioMeta: radioSong?.meta,
          radioNextSong: radioNextSong?.data,
        })
      );
    }
  }, [radioSong?.data]);

  useEffect(() => {
    if (RadioSong?.length > 0) {
      setUrl(RadioSong[0]?.attributes?.URL);
    }
  }, [RadioSong, url]);

  useEffect(() => {
    let lang = globalselectedlang;
    setPlaying(false);
    if (!lang) {
      lang = JSON.parse(handleLang());
    }
    if (lang && lang != "ta") {
      setSort(1);
      setRadioEnable(true);
    } else {
      setSort(0);
      setRadioEnable(true);
    }
  }, [globalselectedlang]);

  const handleVolume = () => {
    setMuted(!muted);
  };

  const router = useRouter();

  const Close = () => {
    dispatch(radioStateChange(false));
  };

  const handleRadioClick = () => {
    dispatch(radioStateChange(true));
  };

  const forward = () => {
    if (pageParam < RadioMeta?.pagination?.total) {
      if (playing) setPlaying(!playing);
      setRadioEnable(true);
      setPageParam((prev) => prev + 1);
      setPageParamNext((next) => next + 1);
      return;
    }
    return;
  };

  const backward = () => {
    if (pageParam > 1) {
      if (playing) setPlaying(!playing);
      setRadioEnable(true);
      setPageParam((prev) => prev - 1);
      setPageParamNext((next) => next - 1);
      return;
    }
    return;
  };

  return (
    <>
      <div
        className={styles.radioContent}
        style={{ display: radioState ? "none" : "block" }}
        onClick={handleRadioClick}
      >
        <button className={styles.radio}>
          <img src="/assests/icons/radio.png" alt="" />
          {/* {homepagedata.radio} */}
        </button>
      </div>

      <div
        className={styles.audioWave}
        style={{ display: radioState ? "flex" : "none" }}
      >
        <div className={styles.audio}>
          <div className={styles.title}>
            <div
              className={styles.titleImg}
              onClick={() => router.push("/radio")}
            >
              <img src="/assests/icons/radio2x.png" alt="" />
            </div>
            <div>
              <h5>
                {" "}
                {RadioSong?.length > 0 && RadioSong[0]?.attributes?.Title}{" "}
              </h5>

              <p>
                {RadioSong?.length > 0 && RadioSong[0]?.attributes?.description}
              </p>
            </div>
          </div>
          <div className={styles.waveWrapper}>
            <div className={styles.icons}>
              <button onClick={backward}>
                <img src="/assests/icons/rewind3.png" alt="" />
              </button>
              <button>
                {playing ? (
                  <BiPause onClick={toggle} className={styles.pause} />
                ) : (
                  <img
                    src="/assests/icons/playaudio.png"
                    alt=""
                    onClick={toggle}
                  />
                )}
              </button>
              <button onClick={forward}>
                <img src="/assests/icons/forward.png" alt="" />
              </button>
              <span style={{ width: "5rem" }}>
                {RadioNextSong?.length > 0 && "Next:"}
                {RadioNextSong?.length > 0 &&
                  RadioNextSong[0]?.attributes?.Title}
              </span>
            </div>

            <div className={styles.waveGif}>
              <img
                src={
                  playing
                    ? "https://media.tenor.com/NjavXXAMRD8AAAAC/sound.gif"
                    : "/assests/images/wave.png"
                }
              />
            </div>

            <div className={styles.volume}>
              {!muted ? (
                <img
                  src="/assests/icons/volume.png"
                  alt=""
                  onClick={handleVolume}
                />
              ) : (
                <FaVolumeMute
                  onClick={handleVolume}
                  className={styles.volIcon}
                />
              )}
            </div>
            <div className={styles.volume2}>
            {RadioNextSong?.length > 0 && "Next:"}
                {RadioNextSong?.length > 0 &&
                  RadioNextSong[0]?.attributes?.Title}
            </div>
          </div>

          <div className={styles.close}>
            <img src="/assests/icons/close.png" alt="" onClick={Close} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Radio;
