import { configureStore } from "@reduxjs/toolkit";

import actions from "../actions/index";
import setUserData from "../actions/userData";
import globalLanguage from "../actions/globalLangSlice";
import radio from "../actions/radioSlice";
import audio from "../actions/audioSlice";
import thirSearch from '../actions/thirumuraiSlice'

export const store = configureStore({
  reducer: {
    features: actions,
    userData: setUserData,
    language: globalLanguage,
    radio: radio,
    audio:audio,
    thirSearch: thirSearch,
  },
});
