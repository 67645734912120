import Link from "next/link";
import axios from "axios";
import { language } from "../data/location";
import { Config, geoLocationConfig } from "../utils/config";
import {
  AiOutlineClose,
  AiFillCaretDown,
  AiOutlineCaretDown,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { BiHome, BiUserCircle, BiLinkExternal } from "react-icons/bi";
import { BsBell } from "react-icons/bs";
import { useRouter } from "next/router";
import { MdClear, MdClose } from "react-icons/md";
import { ImSwitch } from "react-icons/im";
import { FiSearch } from "react-icons/fi";
import { Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { userInfo } from "../api/authApi";
import {
  globalLanguages,
  globalLanguagesForApiChange,
} from "../actions/globalLangSlice";
import {
  handleAuth,
  handleExpire,
  handleLang,
  setlangForUser,
} from "../utils/Helper";

import "semantic-ui-css/semantic.min.css";
import styles from "../styles/Header.module.css";
import { getUpdateBar } from "../hooks/homePageHooks";
import { FindGlobalSearchContent } from "../hooks/globalSearchhooks";
import data from "../data/menu.json";
import { useRef } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
import { useMemo } from "react";
import { setIsLogedInState } from "../actions/userData";
import Image from "next/image";
import langThree from "../public/assests/icons/lang3.png";
import { textSearchSet } from "../actions/thirumuraiSlice";

import bookmarkImg from "../public/assests/icons/bookmarkunfill.svg";

const Header = () => {
  const [windowDimensions, setWindowDimensions] = useState(0);
  useEffect(() => {
    setWindowDimensions(window.innerWidth);
  }, []);

  const router = useRouter();
  const langRef = useRef(null);
  const searchRef = useRef(null);
  const userRef = useRef(null);
  const [user, setUser] = useState({});
  const [lang, setLang] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [Markedread, setMarkedread] = useState(false);
  const [selectedLang, setSelectedLang] = useState("");
  const [notification, setNotification] = useState(false);
  const [Clearnotification, setClearNotification] = useState(false);
  const [currentActive, setCurrentActive] = useState(-1);
  const [search, setSearch] = useState(false);
  const [crossBtn, setCrossBtn] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [globalSearchDesktop, setGlobalSearchDesktop] = useState("");

  const [preferedLang, setPreferedLang] = useState();
  const [submenu1, setsubmenu1] = useState(-1);
  const [submenu2, setsubmenu2] = useState(-1);
  const [submenu3, setsubmenu3] = useState(-1);
  const [submenu4, setsubmenu4] = useState(-1);
  const [submenu5, setsubmenu5] = useState(-1);
  const [submenu6, setsubmenu6] = useState(-1);
  const [mobileUserProfileDropdown, setMobileUserProfileDropdown] =
    useState(false);
  const [location, setLocation] = useState({});
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [reload, setReload] = useState(false);
  const searchForm = { render: false };

  const searchInputRef = useRef(null);

  function handleMobileMenu(number) {
    console.log("first");
    if (currentActive == number) {
      setCurrentActive(-1);
    } else {
      setCurrentActive(number);
    }
  }

  const closeProfileHandlerMobile = () => {
    setMobileUserProfileDropdown(false);
  };

  const handlePathandTab = ({ url, number }) => {
    // console.log(url)
    if (url !== undefined) {
      router.push(url);
      setCurrentActive(number);
      return;
    }
    return;
  };

  const dispatch = useDispatch();
  const IsProfileImageChange = useSelector(
    (state) => state.userData.isProfileImageChange
  );
  let thirmuraiSearchText = useSelector((state) => state.thirSearch.text);

  const prefferedLangs = [
    { text: "English", Value: "en" },
    { text: "हिंदी", Value: "hi" },
    { text: "தமிழ்", Value: "ta" },
  ];

  const backButton= () => {
    const pathSegments = window.location.pathname.split("/");
    const pathName = `${window.location.pathname}`;
    console.log(pathName);
    const firstPathSegment = pathSegments[1];
    window.addEventListener("popstate", (event) => {   

      if (firstPathSegment === "thirumuraiSearch") {
        history.pushState(
          { navigationDirection: "back" },
          "Back",
          "/thirumurai"
        );
        window.location.reload();
      }

      if (firstPathSegment === "thirumurai") {
        if (window.location.pathname !== "/thirumurai") {
          if (thirmuraiSearchText !== '') {
            history.pushState(
              { navigationDirection: "back" },
              "Back",
              `/thirumuraiSearch/search?searchThirumurai=${thirmuraiSearchText} `
            );
            window.location.reload();
          } else {
            history.pushState(
              { navigationDirection: "back" },
              "Back",
              "/thirumurai"
            );
            window.location.reload();
          }
        } else {
          history.pushState(
            { navigationDirection: "back" },
            "Back",
            `/`
          );
        }
      }

      window.location.reload();
    });
  }

  useEffect(() => {
    backButton();
  });

  // console.log(prefferedLangs[0].Value)
  const filter = useSelector((state) => state.language.globalLanguageForApi);
  const updateBarContent = getUpdateBar(filter);
  // const { items: globalSearchData } = FindGlobalSearchContent({
  //   text: globalSearch,
  // });
  const { slug } = router.query;
  const userState = useSelector((state) => state?.userData);

  useEffect(() => {
    const isUser = handleAuth();
    if (isUser !== null) {
      setIsLogedIn(true);
      setUser(JSON.parse(isUser));
    } else {
      setDropDown(false);
      setIsLogedIn(false);
    }
  }, [userState]);

  useEffect(() => {
    console.log("headerSlug", slug);
    if (!slug) return;
    // window.__gcse = {}
    document.getElementById("googlesearch-2").innerHTML = "";
    document.getElementById("googlesearch-0").innerHTML = "";

    console.log("hi there header");
    (function () {
      const cx = "004723011393733804740:mgei4vtavoa";
      const gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(gcse, s);
    })();

    var renderSearchForms = function () {
      console.log("called", document.readyState);
      console.log("searchForm", searchForm.render);

      if (document.readyState === "complete" && searchForm.render == false) {
        console.log("line 145", searchForm);

        searchForm.render = true;
        window.google.search.cse.element.render({
          div: "googlesearch-2",
          tag: "search",
          gname: "gsearch",
        });

        window.google.search.cse.element.render({
          div: "googlesearch-0",
          tag: "search",
          gname: "gsearch",
        });

        window.google.search.cse.element.render({
          div: "googlesearch-1",
          tag: "search",
          gname: "gsearch",
        });
      } else if (searchForm.render == false) {
        searchForm.render = true;

        window.google.setOnLoadCallback(function () {
          window.google.search.cse.element.render({
            div: "googlesearch-2",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });

          window.google.search.cse.element.render({
            div: "googlesearch-0",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });

          window.google.search.cse.element.render({
            div: "googlesearch-1",
            tag: "search",
            gname: "gsearch",
            onload: function () {
              document.getElementById(s).firstElementChild.style.display =
                "none";
            },
          });
        }, true);
      }
    };
    console.log("serchForms", renderSearchForms);
    window.__gcse = {
      parsetags: "explicit",
      callback: renderSearchForms,
    };
  }, [slug]);

  useEffect(() => {
    console.log("hi there");

    const input = document.getElementById("search2");
    const submit = (e) => {
      console.log("on Submit", e);
      if (e.keyCode === 13) {
        const element = window.google.search.cse.element.getElement("gsearch");
        console.log("element", element);
        element.execute(globalSearch);
      }
    };
    input.addEventListener("keyup", submit);

    return () => input.removeEventListener("keyup", submit);
  }, [globalSearch]);

  useEffect(() => {
    console.log("hi there");

    const input = document.getElementById("search0");
    const submit = (e) => {
      console.log("on Submit", e);
      if (e.keyCode === 13) {
        const element = window.google.search.cse.element.getElement("gsearch");
        console.log("element", element);
        element.execute(globalSearchDesktop);
      }
    };
    input.addEventListener("keyup", submit);

    return () => input.removeEventListener("keyup", submit);
  }, [globalSearchDesktop]);

  useEffect(() => {
    if (user.id && !user.img) {
      userInfo(user.id).then((res) =>
        setUser({ ...user, img: res?.avatar?.url || "no" })
      );
    }
  }, [user]);

  useEffect(() => {
    const lang = handleLang();
    console.log("🚀 ~ file: Header.jsx:189 ~ useEffect ~ lang", lang);

    if (!lang) {
      console.log("No Lang Exist");
      const config = geoLocationConfig();
      console.log("config", config);
      axios(config)
        .then((res) => {
          console.log("loc data", res.data);
          setResponse(res.data);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, []);

  const onClickHanlder = () => {
    setLang(!lang);
    searchInputRef.current.focus();
    setSearch(false);
  };

  useEffect(() => {
    if (!response) return;
    let findData = language.filter((e) => {
      if (
        e.country == response.countryCode &&
        e.regionCode == response.region
      ) {
        return e;
      }
    });
    let lancode = findData.length > 0 ? findData[0].lang : "en";
    setlangForUser(lancode);
    setSelectedLang(lancode);
    dispatch(globalLanguages(lancode));
    dispatch(globalLanguagesForApiChange(lancode));
  }, [response]);

  const langHandler = (lancode) => {
    setLang(false);
    setlangForUser(lancode);
    setSelectedLang(lancode);
    dispatch(globalLanguages(lancode));
    dispatch(globalLanguagesForApiChange(lancode));
  };

  const MarkRead = () => {
    setMarkedread(true);
    setNotification(false);
  };

  useOutsideClick(
    (lang ? langRef : "") || (search ? searchRef : userRef),
    (callBack) => {
      if (lang) {
        setLang(false);
      } else if (search && globalSearch == "") {
        // setSearch(false);
      } else {
        setDropDown(false);
      }
    }
  );
  useEffect(() => {
    const lang = handleLang();
    console.log("lang is", lang);
    const language = JSON.parse(lang);

    setSelectedLang(language);
  }, []);

  useMemo(() => {
    if (selectedLang == "en") {
      setPreferedLang(data.en);
    }
    if (selectedLang == "ta") {
      setPreferedLang(data.ta);
    }
    if (selectedLang == "hi") {
      setPreferedLang(data.hi);
    }
  }, [selectedLang]);

  const handleClick = () => {
    if (value.lenght > 0) {
      const element = window.google.search.cse.element.getElement("gsearch");

      element.execute(value);
    }
  };

  const handleClickDesktop = () => {
    if (value.lenght > 0) {
      const element = window.google.search.cse.element.getElement("gsearch");

      element.execute(value);
    }
  };

  const handleChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handleChangeDesktop = (e) => {
    setGlobalSearchDesktop(e.target.value);
  };

  const [sendTo, setSendTo] = useState("");
  const eight = useRef();

  const handleSendTo = (url) => {
    setSendTo(url);
  };

  useEffect(() => {
    if (eight?.current && sendTo !== "") {
      eight?.current?.click();
    }
  }, [sendTo]);

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img
              src="/assests/icons/logo.png"
              alt="logo"
              onClick={() => router.push("/")}
            />
          </div>

          <div className={styles.links}>
            <div>
              <Link href="">
                <a
                  className={styles.linkOne}
                  style={{ textTransform: "uppercase" }}
                >
                  {preferedLang?.menu1.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>

              <div className={styles.dOne}>
                {preferedLang?.menu1.data.map((menu, index) => {
                  return (
                    <div
                      className={`${styles.subDone} ${
                        currentActive == index ? styles.subActiveLink : ""
                      }`}
                      onClick={() =>
                        handlePathandTab({
                          url: menu.url,
                          number: index,
                        })
                      }
                      key={index}
                    >
                      <h6>{menu.title}</h6>
                      {/* <p>
                    Lorem ipsum dolor sit <br /> amet, consectetur <br />
                    adipiscing elit,
                  </p> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <Link href="">
                <a
                  className={styles.linkTwo}
                  style={{ textTransform: "uppercase" }}
                >
                  {preferedLang?.menu2?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>
              <div className={styles.dTwo}>
                {preferedLang?.menu2?.data.map((menu, index) => {
                  return (
                    <div
                      className={`${styles.subDtwo} ${
                        currentActive == index ? styles.subActiveLink : ""
                      }`}
                      key={index}
                      // onClick={() => HandlePath("/lordshivaforms")}
                      onClick={() =>
                        handlePathandTab({ url: menu.url, number: index })
                      }
                    >
                      <h6 className={styles.god}> {menu.title}</h6>
                      <div>
                        <h6>
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.title}
                        </h6>
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.data.map((item, index) => {
                            return (
                              <p
                                key={index}
                                onClick={() => router.push(item.url)}
                              >
                                {" "}
                                {item.title}{" "}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <Link href="">
                <a
                  className={styles.linkThree}
                  style={{ textTransform: "uppercase" }}
                >
                  {preferedLang?.menu3?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>
              <div className={styles.dFour}>
                {preferedLang?.menu3?.data.map((menu, index) => {
                  return (
                    <>
                      <div
                        className={`${styles.subThree} ${
                          currentActive == index ? styles.subActiveLink : ""
                        }`}
                        // onClick={() => HandlePath("/news")}
                        key={index}
                      >
                        <h3
                          className={styles.newLinkBold}
                          onClick={() =>
                            handlePathandTab({ url: menu.url, number: index })
                          }
                          // style={{cursor:"auto"}}
                        >
                          {menu.title}
                        </h3>
                        {menu.hasOwnProperty("childMenu") ? (
                          <h3 style={{ marginTop: "-15px" }}>
                            {menu?.hasOwnProperty("childMenu") &&
                              menu?.childMenu?.title}
                          </h3>
                        ) : null}

                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.data.map((item, index) => {
                            return (
                              <div
                                className={styles.underPara}
                                key={index}
                                //  style={{marginTop:"0px",paddingTop:"0px"
                                // ,backgroundColor:"red"
                                // }}
                              >
                                <p
                                  onClick={() =>
                                    handlePathandTab({
                                      url: item.url,
                                      number: index,
                                    })
                                  }
                                >
                                  {item.title}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })}

                {/* <div className={styles.subThree}>
                  <h3 className={styles.newLinkBold3}>
                  
                    abc
                    </h3>
            
                  <h5
                    className={currentActive == index ? styles.subActiveLink : ""}
                
                    onClick={() =>
                      handlePathandTab({ url: (submenu.url), number: index })
                    }
                  >
      
submenutitle
                 
                  </h5>
 
                </div> */}
              </div>
            </div>

            <div className={styles.dtempleswrapper}>
              <Link href="">
                <a className={styles.linkSix}>
                  {preferedLang?.menu4?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>

              <div className={styles.dSix}>
                {preferedLang?.menu4?.data.map((menu, index) => {
                  return (
                    <>
                      <p onClick={() => router.push(menu.url)} key={index}>
                        {" "}
                        {menu.title}{" "}
                      </p>
                      <div>
                        <h5>
                          {menu?.hasOwnProperty("childMenu") &&
                            menu?.childMenu?.title}{" "}
                        </h5>
                      </div>
                      <div>
                        {menu?.hasOwnProperty("childMenu") &&
                          menu?.childMenu?.data.map((item, index) => {
                            return (
                              <div key={index}>
                                <p onClick={() => router.push(item.url)}>
                                  {item.title}{" "}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={styles.fiveWrapperMain}>
              <Link href="">
                <a className={styles.linkFive}>
                  {preferedLang?.menu5?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>

              <div
                className={styles.dFive}
                style={{
                  left:
                    selectedLang == "ta" || selectedLang == "hi" ? "38rem" : "",
                }}
              >
                <div className={styles.mainMenuWrapper}>
                  {preferedLang?.menu5?.data?.map((item, index) => {
                    return (
                      <h5
                        onClick={() => {
                          router.push(item.url);
                          if (thirmuraiSearchText) {
                            dispatch(textSearchSet(""));
                          }
                        }}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        {" "}
                        {item?.title}{" "}
                      </h5>
                    );
                  })}
                </div>
                <div
                  className={styles.SubMenuWrapperfive}
                  style={{
                    flexDirection:
                      selectedLang == "hi" || selectedLang == "ta"
                        ? "column"
                        : "row",
                  }}
                >
                  {preferedLang?.menu5?.data?.map((item, index) => {
                    return (
                      <div className={styles.mainContent} key={index}>
                        {item.hasOwnProperty("chidMenu") &&
                        item.chidMenu.title ? (
                          <h5> {item?.chidMenu?.title} </h5>
                        ) : null}

                        {item?.chidMenu?.data?.map((menu, index) => {
                          return (
                            <p
                              onClick={() => {
                                router.push(menu.url);
                              }}
                              style={{ cursor: "pointer" }}
                              key={index}
                            >
                              {" "}
                              {menu?.title}{" "}
                            </p>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <Link href="">
                <a className={styles.linkSix}>
                  {preferedLang?.menu6?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>
              <div className={styles.dSix}>
                {preferedLang?.menu6?.data.map((menu, index) => {
                  return (
                    <>
                      <div key={index}>
                        {/* <Link href=""> */}
                        <h5
                          className={
                            currentActive == index ? styles.subActiveLink : ""
                          }
                          onClick={() => {
                            router.push(menu?.url);
                            setCurrentActive(index);
                          }}
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                        >
                          {menu.title}
                        </h5>
                        {/* </Link> */}
                      </div>

                      <div>
                        <h5 style={{ fontWeight: "bold" }}>
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.title}
                        </h5>
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.data.map((item, index) => {
                            return (
                              <div className={styles.dsixItems} key={index}>
                                <p
                                  onClick={() => router.push(item?.url)}
                                  style={{ color: "grey" }}
                                >
                                  {" "}
                                  {item.title}{" "}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div>
              <Link href="">
                <a className={styles.linkSeven}>
                  {preferedLang?.menu7?.title}
                  <AiFillCaretDown className={styles.icon} />
                </a>
              </Link>
              <div className={styles.dSeven}>
                {preferedLang?.menu7?.data.map((menu, index) => {
                  return (
                    <div key={index}>
                      <h5
                        style={{ fontFamily: "Lora", cursor: "pointer" }}
                        onClick={() => router.push(menu.url)}
                      >
                        {menu.title}
                      </h5>

                      <div>
                        <h5 style={{ fontFamily: "Lora" }}>
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.title}
                        </h5>
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.data.map((item, index) => {
                            return (
                              <div key={index}>
                                <p
                                  onClick={() => router.push(item.url)}
                                  key={index}
                                >
                                  {" "}
                                  {item.title}{" "}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div>
                <Link href="">
                  <a className={styles.linkEight}>
                    {preferedLang?.menu8?.title}
                    <AiFillCaretDown className={styles.icon} />
                  </a>
                </Link>

                <div
                  className={styles.dEight}
                  style={{
                    right: preferedLang?.menu8?.title
                      .toLowerCase()
                      .includes("other languages")
                      ? "15vw"
                      : null,
                  }}
                >
                  {windowDimensions > 770 ? (
                    <div
                      style={{
                        gap: "0.5rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div>
                        {preferedLang?.menu8?.data.map((menu, index) =>
                          // <p
                          //   style={{ margin: 0, padding: 0 }}
                          //   key={index}
                          //   onClick={() => router.push(menu.url)}
                          // >
                          //   {menu.title}
                          // </p>
                          menu?.url?.includes("https") ? (
                            <>
                              <a
                                ref={eight}
                                style={{ display: "none" }}
                                target="_blank"
                                href={sendTo}
                                rel="noreferrer"
                              ></a>
                              <p
                                style={{ margin: 0, padding: 0 }}
                                onClick={() => handleSendTo(menu?.url)}
                              >
                                {menu.title}{" "}
                                <BiLinkExternal className={styles.icon} />
                              </p>
                            </>
                          ) : (
                            <p
                              key={index}
                              style={{ margin: 0, padding: 0 }}
                              onClick={() => router.push(menu.url)}
                            >
                              {" "}
                              {menu.title}
                            </p>
                          )
                        )}
                      </div>

                      <div
                        style={{
                          gap: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {preferedLang?.menu8?.data.map((menu, index) => {
                          return (
                            <div key={index}>
                              <h5>
                                {menu.hasOwnProperty("childMenu") &&
                                  menu.childMenu.title}
                              </h5>
                              <div>
                                {menu.hasOwnProperty("childMenu") &&
                                  menu.childMenu.data.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <p
                                          onClick={() => router.push(item.url)}
                                        >
                                          {" "}
                                          {item.title}{" "}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <>
                      {preferedLang?.menu8?.data.map((menu, index) => {
                        return (
                          <div key={index}>
                            <p onClick={() => router.push(menu.url)}>
                              {menu.title}
                            </p>
                            <h5>
                              {menu.hasOwnProperty("childMenu") &&
                                menu.childMenu.title}
                            </h5>
                            <div>
                              {menu.hasOwnProperty("childMenu") &&
                                menu.childMenu.data.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <p onClick={() => router.push(item.url)}>
                                        {" "}
                                        {item.title}{" "}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            {preferedLang?.menu9 ? (
              <>
                <div>
                  <Link href="">
                    <a className={styles.linkEight}>
                      {preferedLang?.menu9?.title}
                      <AiFillCaretDown className={styles.icon} />
                    </a>
                  </Link>

                  <div
                    className={styles.dEight}
                    style={{
                      right: preferedLang?.menu9?.title
                        .toLowerCase()
                        .includes("other languages")
                        ? "15vw"
                        : null,
                    }}
                  >
                    {windowDimensions > 770 ? (
                      <div
                        style={{
                          gap: "0.5rem",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div>
                          {preferedLang?.menu9?.data.map((menu, index) => (
                            <p
                              style={{ margin: 0, padding: 0 }}
                              key={index}
                              onClick={() => router.push(menu.url)}
                            >
                              {menu.title}
                            </p>
                          ))}
                        </div>

                        <div
                          style={{
                            gap: "1rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {preferedLang?.menu9?.data.map((menu, index) => {
                            return (
                              <div key={index}>
                                <h5>
                                  {menu.hasOwnProperty("childMenu") &&
                                    menu.childMenu.title}
                                </h5>
                                <div>
                                  {menu.hasOwnProperty("childMenu") &&
                                    menu.childMenu.data.map((item, index) => {
                                      return (
                                        <div key={index}>
                                          <p
                                            onClick={() =>
                                              router.push(item.url)
                                            }
                                          >
                                            {" "}
                                            {item.title}{" "}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <>
                        {preferedLang?.menu9?.data.map((menu, index) => {
                          return (
                            <div key={index}>
                              <p onClick={() => router.push(menu.url)}>
                                {menu.title}
                              </p>
                              <h5>
                                {menu.hasOwnProperty("childMenu") &&
                                  menu.childMenu.title}
                              </h5>
                              <div>
                                {menu.hasOwnProperty("childMenu") &&
                                  menu.childMenu.data.map((item, index) => {
                                    return (
                                      <div key={index}>
                                        <p
                                          onClick={() => router.push(item.url)}
                                        >
                                          {" "}
                                          {item.title}{" "}
                                        </p>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            {preferedLang?.menu10 ? (
              <div>
                <Link href="">
                  <a className={styles.linkEight}>
                    {preferedLang?.menu10?.title}
                    <AiFillCaretDown className={styles.icon} />
                  </a>
                </Link>
                <div className={styles.dEight}>
                  {preferedLang?.menu10?.data.map((menu, index) => {
                    return (
                      <div key={index}>
                        <p onClick={() => router.push(menu.url)}>
                          {" "}
                          {menu.title}{" "}
                          {menu.isExternal ? (
                            <BiLinkExternal className={styles.icon} />
                          ) : (
                            " "
                          )}
                        </p>
                        <div>
                          <h5>
                            {menu.hasOwnProperty("childMenu") &&
                              menu.childMenu.title}
                          </h5>
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.data.map((item, index) => {
                              return (
                                <div key={index}>
                                  <p onClick={() => router.push(item.url)}>
                                    {" "}
                                    {item.title}
                                    {item.isExternal ? (
                                      <BiLinkExternal className={styles.icon} />
                                    ) : (
                                      " "
                                    )}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.login}>
            {isLogedIn === false ? (
              <Link href="/login">
                <a>LOGIN </a>
              </Link>
            ) : (
              <div className={styles.profile} ref={userRef}>
                <p onClick={() => setDropDown(!dropDown)}>
                  {user?.img !== "no" || IsProfileImageChange !== "" ? (
                    <img
                      alt=""
                      src={
                        IsProfileImageChange !== ""
                          ? IsProfileImageChange
                          : `${user?.img}`
                      }
                    />
                  ) : (
                    <span className={styles.UserIconImage}>
                      <FaUserCircle fontSize={30} />
                    </span>
                  )}
                  {user?.name?.slice(0, 5)}... <AiOutlineCaretDown />
                </p>
              </div>
            )}
            <div
              className={styles.profileDropDown}
              style={{ display: dropDown === false ? "none" : "" }}
            >
              <h3>
                {user?.img !== "no" ? (
                  <img src={`${user?.img}`} width={100} height={100} alt="#" />
                ) : (
                  <FaUserCircle />
                )}
                Hello , {user?.name?.slice(0, 15)}...
              </h3>
              <hr />
              <p onClick={() => router.push("/my-saved-items")}>
                <span>
                  <img src={bookmarkImg} alt="" />
                </span>
                Saved items
              </p>
              <p onClick={() => router.push("/profile")}>
                <span>
                  <FaUserCircle />
                </span>
                Profile Settings
              </p>
              <p>
                <span>
                  <BiHome />
                </span>
                My Shaivite Hubs
              </p>
              <p
                onClick={() => {
                  handleExpire();
                  setIsLogedIn(false);
                  setDropDown(false);
                  dispatch(setIsLogedInState(false));
                }}
              >
                <span>
                  <ImSwitch />
                </span>
                Logout
              </p>
            </div>
            <Link href="/contact">
              <a style={{ textTransform: "uppercase" }}>
                {(selectedLang == "en" && "Contact") ||
                  (selectedLang == "ta" && "தொடர்பு") ||
                  (selectedLang == "hi" && "संपर्क")}
              </a>
            </Link>
            |
            <div
              ref={searchRef}
              className={styles.searchIconWrapper}
              style={{ backgroundColor: search ? "white" : "transparent" }}
            >
              <span
                onClick={() => {
                  setSearch(!search);
                  setLang(false);
                  console.log("changle in teh search bar ");
                  // searchInputRef.current.focus();
                }}
                style={{ cursor: "pointer" }}
              >
                <FiSearch className={styles.searchIcon} />
              </span>
              <div
                className={styles.searchcontentWrapper}
                style={{ display: search ? "flex" : "none" }}
              >
                <div className={styles.searchInputWrapperContent}>
                  <div id="search-container-1">
                    <input
                      name="search0"
                      id="search0"
                      value={globalSearch}
                      onChange={handleChangeDesktop}
                      style={{ display: "none" }}
                    />
                    <button
                      id="submit"
                      onClick={handleClickDesktop}
                      style={{ display: "none" }}
                    >
                      送出
                    </button>
                    <div id="googlesearch-0"></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref={langRef}
              className={styles.imgWrapper}
              style={{ backgroundColor: lang ? "white" : "transparent" }}
            >
              <img
                style={{
                  cursor: "pointer",
                }}
                src="/assests/icons/lang3.png"
                onClick={onClickHanlder}
                alt=""
              />
            </div>
            <div
              className={styles.langwrapper}
              style={{ display: lang ? "flex" : "none" }}
            >
              <p className={styles.change}>Change Language</p>
              <div className={styles.langOpt}>
                {prefferedLangs.map((lang) => (
                  <p
                    key={lang.Value}
                    className={
                      (selectedLang || "en") === lang.Value ? styles.active : ""
                    }
                    onClick={() => langHandler(lang.Value)}
                  >
                    {lang.text}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.mobileUserProfile}>
            {isLogedIn === true ? (
              <div
                className={styles.profile}
                onClick={() => setMobileUserProfileDropdown(true)}
              >
                <p onClick={() => setDropDown(!dropDown)}>
                  {user?.img !== "no" || IsProfileImageChange !== "" ? (
                    <img
                      alt=""
                      src={
                        IsProfileImageChange !== ""
                          ? IsProfileImageChange
                          : `${user?.img}`
                      }
                    />
                  ) : (
                    <span className={styles.UserIconImage}>
                      <FaUserCircle fontSize={30} />
                    </span>
                  )}
                </p>
              </div>
            ) : null}
            {isLogedIn === true && mobileUserProfileDropdown == true ? (
              <div
                className={styles.profileMobileDropdownWrapper}
                style={{ display: mobileUserProfileDropdown ? "flex" : "none" }}
              >
                <div className={styles.profileMobileDropdown}>
                  <div className={styles.closeDragbtn}>
                    <button onClick={closeProfileHandlerMobile}> </button>
                  </div>

                  <div
                    className={styles.noting}
                    // style={{ display: dropDown === false ? "none" : "" }}
                  >
                    <h3>
                      {user?.img !== "no" || IsProfileImageChange !== "" ? (
                        <img
                          alt=""
                          src={
                            IsProfileImageChange !== ""
                              ? IsProfileImageChange
                              : `${user?.img}`
                          }
                        />
                      ) : (
                        <span className={styles.dropdownUserIcon}>
                          <FaUserCircle fontSize={30} />
                        </span>
                      )}
                      Hello , {user?.name}
                    </h3>
                    <hr />
                    <p
                      onClick={() => {
                        setMobileUserProfileDropdown(false);
                        router.push("/my-saved-items");
                      }}
                    >
                      <span>
                        <img src={bookmarkImg} alt="" />
                      </span>
                      Saved items
                    </p>
                    <p
                      onClick={() => {
                        setMobileUserProfileDropdown(false);
                        router.push("/profile");
                      }}
                    >
                      <span>
                        <BiUserCircle />
                      </span>
                      Profile Settings
                    </p>
                    {/* <p>
                      <span>
                        <BiHome />
                      </span>
                      My Shaivite Hubs
                    </p> */}
                    <p
                      onClick={() => {
                        handleExpire();
                        setIsLogedIn(false);
                        setDropDown(false);
                        dispatch(setIsLogedInState(false));
                      }}
                    >
                      <span>
                        <ImSwitch />
                      </span>
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.hamburger}>
            <button onClick={() => setHamburger(!hamburger)}>
              {hamburger ? <AiOutlineClose /> : <FaBars />}
            </button>
          </div>
        </div>

        <div className={styles.updateWrapper}>
          <div className={styles.left}>
            <h2>
              {" "}
              {(selectedLang == "en" && "Attention") ||
                (selectedLang == "ta" && "கவனத்திற்கு") ||
                (selectedLang == "hi" && "ध्यान")}{" "}
              |
            </h2>
          </div>
          <div className={styles.right}>
            {updateBarContent?.data?.map(({ attributes, id }, i) => (
              <span
                key={id}
                dangerouslySetInnerHTML={{
                  __html: attributes?.heading,
                }}
              />
            ))}
          </div>
        </div>

        <div
          className={styles.notificationWrapper}
          style={{ display: notification ? "flex" : "none" }}
        >
          <div className={styles.notification}>
            <div className={styles.hWrpr}>
              <div>
                <h1>My Notifications</h1>
                <p>You can view all the updates you receive here</p>
              </div>
              <div>
                <span onClick={() => setNotification(false)}>
                  <MdClear />
                </span>
              </div>
            </div>

            <div
              className={styles.notWrapper}
              style={{ display: Clearnotification ? "none" : "block" }}
            >
              <div className={styles.scnd}>
                <div>
                  <h4>
                    <button></button> XYZ Shaivite Hub created a new event
                  </h4>
                  <p>“Veda Patasalai” was added 30 mins ago </p>
                </div>
              </div>
              <div className={styles.thrd}>
                <div>
                  <h4>
                    <button></button> Veda Patasalai is happening in 2 days
                  </h4>
                  <p>12h ago </p>
                </div>
              </div>
              <div className={styles.frth}>
                <div>
                  <h4>
                    <button></button> Lorem Ipsum dolor set
                  </h4>
                  <p>Dolor set lorem ipsum 30 mins ago </p>
                </div>
              </div>
              <div className={styles.fve}>
                <div>
                  <h4>
                    <button></button> You have added “Audio file 1” to your
                    saved items
                  </h4>
                  <p>2 days ago </p>
                </div>
              </div>
            </div>

            <div className={styles.sx}>
              <div>
                <h4 onClick={() => setClearNotification(true)}>
                  Clear notifications
                </h4>
              </div>
              <div>
                <h4 onClick={MarkRead}>Mark all as read</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----------------------------mobile menu----------------------------------------------------- */}

      <div
        className={styles.MobileMenu}
        style={{ display: hamburger ? "flex" : "none" }}
      >
        <div className={styles.mobLogo}>
          <div className={styles.logo}>
            <img
              src="/assests/icons/logo.png"
              alt="logo"
              onClick={() => router.push("/")}
            />
          </div>

          <div className={styles.closeLoginBtn}>
            {isLogedIn == false ? (
              <button
                className={styles.closeLoginButton}
                onClick={() => {
                  router.push("/login");
                  setHamburger(false);
                }}
              >
                Login
              </button>
            ) : null}
            <MdClose
              className={styles.mobiCON}
              onClick={() => setHamburger(false)}
            />
          </div>
        </div>
        {/* <div className={styles.mobInput}>
          <Input
            icon="search"
            iconPosition="left"
            placeholder="Search..."
            className={styles.mobInput}
          />
        </div> */}
        <div className={styles.inputbox}>
          <div className={styles.searchInputWrapperContent}>
            <div id="search-container-1">
              <input
                ref={searchInputRef}
                name="search2"
                id="search2"
                value={globalSearch}
                onChange={handleChange}
                style={{ display: "none" }}
              />
              <button
                id="submit"
                onClick={handleClick}
                style={{ display: "none" }}
              >
                送出
              </button>
              <div id="googlesearch-2"></div>
            </div>
          </div>

          <button className={styles.yellowBtn}>
            <img src="../public/assests/icons/sIcon.png" alt="" />
          </button>
        </div>
        <div className={styles.mobLangWrapper}>
          <div className={styles.langImg}>
            <img src={langThree} alt="" />
            <span className={styles.langImgSpan}>CHANGE LANGUAGE</span>
          </div>
          <div className={styles.mobLang}>
            {prefferedLangs.map((data, _Value) => {
              return (
                <button
                  className={
                    selectedLang === data.Value ? styles.activeMobileLang : ""
                  }
                  onClick={() => {
                    langHandler(data.Value);
                    setHamburger(false);
                  }}
                  key={data.Value}
                >
                  {data.text}
                </button>
              );
            })}
          </div>
        </div>
        <hr />

        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 1 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(1)}
          >
            {preferedLang?.menu1.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}

          <div
            className={styles.mobSubDownLink}
            style={{ display: currentActive == 1 ? "block" : "none" }}
          >
            {preferedLang?.menu1.data.map((menu, index) => {
              return (
                <p
                  onClick={() => {
                    router.push(menu.url);
                    setHamburger(false);
                  }}
                  key={index}
                >
                  {" "}
                  {menu.title}{" "}
                </p>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 2 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(2)}
          >
            {preferedLang?.menu2.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div
            className={styles.mobSubDownLink}
            style={{ display: currentActive == 2 ? "block" : "none" }}
          >
            {preferedLang?.menu2.data.map((menu, i) => {
              return (
                <>
                  <div key={i}>
                    <p
                      onClick={() => {
                        router.push(menu.url);
                        setHamburger(false);
                      }}
                    >
                      {menu.title}
                    </p>
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        if (submenu1 == i) {
                          setsubmenu1(-1);
                          return;
                        }
                        setsubmenu1(i);
                      }}
                    >
                      {menu.hasOwnProperty("childMenu") &&
                      menu.childMenu.title ? (
                        <h6
                          className={submenu1 ? styles.subActiveLinkMobile : ""}
                        >
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.title}
                          <AiFillCaretDown className={styles.icon} />
                        </h6>
                      ) : null}
                    </div>
                    <div style={{ display: submenu1 == i ? "block" : "none" }}>
                      {menu.hasOwnProperty("childMenu") &&
                        menu.childMenu.data.map((item, index) => {
                          return (
                            // <Link href={item?.url}>
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {" "}
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 3 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(3)}
          >
            {preferedLang?.menu3.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}

          <div
            style={{ display: currentActive == 3 ? "block" : "none" }}
            className={styles.mobSubDownLink}
          >
            {preferedLang?.menu3?.data?.map((menu, i) => {
              return (
                <>
                  <div>
                    <p
                      key={i}
                      onClick={() => {
                        router.push(menu.url);
                        setHamburger(false);
                      }}
                    >
                      {" "}
                      {menu.title}{" "}
                    </p>
                  </div>
                  <div>
                    {menu?.hasOwnProperty("childMenu") &&
                    menu?.childMenu.title ? (
                      <h4
                        onClick={() => {
                          if (submenu2 == i) {
                            setsubmenu2(-1);
                            return;
                          }
                          setsubmenu2(i);
                        }}
                        className={submenu2 ? styles.subActiveLinkMobile : ""}
                      >
                        {menu?.hasOwnProperty("childMenu") &&
                          menu?.childMenu.title}
                        <AiFillCaretDown className={styles.icon} />
                      </h4>
                    ) : null}
                    <div style={{ display: submenu2 == i ? "block" : "none" }}>
                      {menu?.hasOwnProperty("childMenu") &&
                        menu?.childMenu?.data?.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {" "}
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={
              currentActive == 9
                ? styles.subActiveLinkMobile
                : styles.subLinkMobile
            }
            onClick={() => handleMobileMenu(9)}
          >
            {preferedLang?.menu4.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div
            className={styles.mobDropDownLink}
            style={{ display: currentActive == 9 ? "block" : "none" }}
          >
            {preferedLang?.menu4?.data?.map((menu, i) => {
              return (
                <>
                  <p
                    onClick={() => {
                      router.push(menu.url);
                      setHamburger(false);
                    }}
                  >
                    {" "}
                    {menu.title}{" "}
                  </p>
                  <div>
                    {menu.hasOwnProperty("childMenu") &&
                    menu.childMenu.title ? (
                      <h4
                        className={submenu3 ? styles.subActiveLinkMobile : ""}
                        onClick={() => {
                          if (submenu3 == i) {
                            setsubmenu3(-1);
                            return;
                          }
                          setsubmenu3(i);
                        }}
                      >
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.title}
                        <AiFillCaretDown className={styles.icon} />
                      </h4>
                    ) : null}
                    <div style={{ display: submenu3 == i ? "block" : "none" }}>
                      {menu.hasOwnProperty("childMenu") &&
                        menu.childMenu.data.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {" "}
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 10 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(10)}
          >
            {preferedLang?.menu5.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div style={{ display: currentActive == 10 ? "block" : "none" }}>
            {preferedLang?.menu5?.data?.map((menu, i) => {
              // console.log(menu)
              return (
                <>
                  <div>
                    <p
                      onClick={() => {
                        router.push(menu.url);
                        setHamburger(false);
                      }}
                    >
                      {" "}
                      {menu.title}{" "}
                    </p>
                  </div>

                  <div>
                    {menu.hasOwnProperty("chidMenu") &&
                    menu?.chidMenu?.title ? (
                      <h4
                        className={submenu4 ? styles.subActiveLinkMobile : ""}
                        onClick={() => {
                          if (submenu4 == i) {
                            setsubmenu4(-1);
                            return;
                          }
                          setsubmenu4(i);
                        }}
                      >
                        {menu.hasOwnProperty("chidMenu") &&
                          menu?.chidMenu?.title}
                        <AiFillCaretDown className={styles.icon} />
                      </h4>
                    ) : null}

                    <div style={{ display: submenu4 == i ? "block" : "none" }}>
                      {menu.hasOwnProperty("chidMenu") &&
                        menu?.chidMenu?.data?.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {" "}
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 4 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(4)}
          >
            {preferedLang?.menu6.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div
            style={{ display: currentActive == 4 ? "block" : "none" }}
            className={styles.mobSubDownLink}
          >
            {preferedLang?.menu6?.data?.map((menu, i) => {
              return (
                <>
                  <p
                    onClick={() => {
                      router.push(menu.url);
                      setHamburger(false);
                    }}
                  >
                    {" "}
                    {menu.title}{" "}
                  </p>
                  <div>
                    {menu.hasOwnProperty("childMenu") &&
                    menu.childMenu.title ? (
                      <h4
                        className={submenu5 ? styles.subActiveLinkMobile : ""}
                        onClick={() => {
                          if (submenu5 == i) {
                            setsubmenu5(-1);
                            return;
                          }
                          setsubmenu5(i);
                        }}
                      >
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.title}
                        <AiFillCaretDown className={styles.icon} />
                      </h4>
                    ) : null}
                    <div style={{ display: submenu5 == i ? "block" : "none" }}>
                      {menu.hasOwnProperty("childMenu") &&
                        menu.childMenu.data.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 5 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(5)}
          >
            {preferedLang?.menu7.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div
            style={{ display: currentActive == 5 ? "block" : "none" }}
            className={styles.mobSubDownLink}
          >
            {preferedLang?.menu7?.data?.map((menu, i) => {
              return (
                <>
                  <p
                    onClick={() => {
                      router.push(menu.url);
                      setHamburger(false);
                    }}
                  >
                    {" "}
                    {menu.title}{" "}
                  </p>
                  {menu.hasOwnProperty("childMenu") && menu.childMenu.title ? (
                    <h4
                      className={submenu6 ? styles.subActiveLinkMobile : ""}
                      onClick={() => {
                        if (submenu6 == i) {
                          setsubmenu6(-1);
                          return;
                        }
                        setsubmenu6(i);
                      }}
                    >
                      {menu.hasOwnProperty("childMenu") && menu.childMenu.title}
                      <AiFillCaretDown className={styles.icon} />
                    </h4>
                  ) : null}
                  <div style={{ display: submenu6 == i ? "block" : "none" }}>
                    {menu.hasOwnProperty("childMenu") &&
                      menu.childMenu.data.map((item, index) => {
                        return (
                          <p
                            key={index}
                            onClick={() => {
                              router.push(item.url);
                              setHamburger(false);
                            }}
                            className={styles.submenuItem}
                          >
                            {" "}
                            {item.title}{" "}
                          </p>
                        );
                      })}
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={styles.mobDropDownLink}>
          {/* <Link href=""> */}
          <span
            className={currentActive == 6 ? styles.subActiveLinkMobile : ""}
            onClick={() => handleMobileMenu(6)}
          >
            {preferedLang?.menu8.title}
            <AiFillCaretDown className={styles.icon} />
          </span>
          {/* </Link> */}
          <div
            style={{ display: currentActive == 6 ? "block" : "none" }}
            className={styles.mobSubDownLink}
          >
            {preferedLang?.menu8?.data?.map((menu, index) => {
              return (
                <>
                  <p
                    onClick={() => {
                      router.push(menu.url);
                      setHamburger(false);
                    }}
                  >
                    {" "}
                    {menu.title}{" "}
                  </p>
                  <div>
                    {menu.hasOwnProperty("childMenu") &&
                    menu.childMenu.title ? (
                      <h4
                        className={submenu5 ? styles.subActiveLinkMobile : ""}
                        onClick={() => {
                          if (submenu5 == index) {
                            setsubmenu5(-1);
                            return;
                          }
                          setsubmenu5(index);
                        }}
                      >
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.title}
                        <AiFillCaretDown className={styles.icon} />
                      </h4>
                    ) : null}
                    <div
                      style={{ display: submenu5 == index ? "block" : "none" }}
                    >
                      {menu.hasOwnProperty("childMenu") &&
                        menu.childMenu.data.map((item, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                router.push(item.url);
                                setHamburger(false);
                              }}
                              className={styles.submenuItem}
                            >
                              {" "}
                              {item.title}{" "}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        {preferedLang?.menu9 ? (
          <div className={styles.mobDropDownLink}>
            {/* <Link href=""> */}
            <span
              className={currentActive == 11 ? styles.subActiveLinkMobile : ""}
              onClick={() => handleMobileMenu(11)}
            >
              {preferedLang?.menu9.title}
              <AiFillCaretDown className={styles.icon} />
            </span>
            {/* </Link> */}
            <div
              style={{ display: currentActive == 11 ? "block" : "none" }}
              className={styles.mobSubDownLink}
            >
              {preferedLang?.menu9?.data?.map((menu, index) => {
                return (
                  <>
                    <p
                      onClick={() => {
                        router.push(menu.url);
                        setHamburger(false);
                      }}
                    >
                      {" "}
                      {menu.title}{" "}
                    </p>
                    <div>
                      {menu.hasOwnProperty("childMenu") &&
                      menu.childMenu.title ? (
                        <h4
                          className={submenu5 ? styles.subActiveLinkMobile : ""}
                          onClick={() => {
                            if (submenu5 == index) {
                              setsubmenu5(-1);
                              return;
                            }
                            setsubmenu5(index);
                          }}
                        >
                          {menu.hasOwnProperty("childMenu") &&
                            menu.childMenu.title}
                          <AiFillCaretDown className={styles.icon} />
                        </h4>
                      ) : null}
                      <div
                        style={{
                          display: submenu5 == index ? "block" : "none",
                        }}
                      >
                        {menu.hasOwnProperty("childMenu") &&
                          menu.childMenu.data.map((item, index) => {
                            return (
                              <p
                                key={index}
                                onClick={() => {
                                  router.push(item.url);
                                  setHamburger(false);
                                }}
                                className={styles.submenuItem}
                              >
                                {" "}
                                {item.title}{" "}
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        ) : null}

        {preferedLang?.menu10 ? (
          <div className={styles.mobDropDownLink}>
            {/* <Link href=""> */}
            <span
              className={currentActive == 12 ? styles.subActiveLinkMobile : ""}
              onClick={() => handleMobileMenu(12)}
            >
              {preferedLang?.menu10.title}
              <AiFillCaretDown className={styles.icon} />
            </span>
            {/* </Link> */}
            <div
              style={{ display: currentActive == 12 ? "block" : "none" }}
              className={styles.mobSubDownLink}
            >
              {preferedLang?.menu10?.data?.map((menu, index) => {
                return (
                  <>
                    <p
                      onClick={() => {
                        router.push(menu.url);
                        setHamburger(false);
                      }}
                    >
                      {" "}
                      {menu.title}{" "}
                    </p>
                  </>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Header;
