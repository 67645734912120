import { useQuery } from "react-query";
import { getRadios } from "../api/radioApi";

export function findRadios(pageParam,sort,radioEnable) {
    const { data, isLoading } = useQuery(["radio", { pageParam , sort }], getRadios,
    {
      enabled: radioEnable !== false,
    }
    );
  
    if (isLoading) return isLoading;
    else return data;
  }

  
