// thirumuraiSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  text: "",
};

const thirumuraiSlice = createSlice({
  name: "thirSearch",
  initialState,
  reducers: {
    textSearchSet: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { textSearchSet } = thirumuraiSlice.actions;
export default thirumuraiSlice.reducer;
