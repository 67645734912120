import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "../styles/PDFviwer.module.css";


export const Pdf = ({ uri, setPopup }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openInNewTab = () => {
    // window.open(uri, "_blank");
  };

  return (
    <div className={styles.popupPDFwRAPPER}>
      <div className={styles.popupPDF}>
        <div className={styles.pdfHead}>
          <h2>PDF Preview</h2>
          <span onClick={() => setPopup(false)}>
            <AiOutlineClose />
          </span>
        </div>

        <div className={styles.popupHeadPdf}>
        <object data={uri} type="application/pdf" width="auto" 
                height="auto" className={styles.pdfRenderer}  >

</object>
          </div>

      </div>
    </div>
  );
};
